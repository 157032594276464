import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { FRAGMENT_PROPERTY_CARD } from "../../Property/PropertyCard/PropertyCard.hook";
import { isValidCharacters } from "../../../Utils/Functions";
import { useDebouncedCallback } from "../../../GlobalHooks/useDebounce";

const KEYWORD_LOCATION_QUERY = gql`
	query Location($strSearch: String!) {
		searchLocation(searchTerm: $strSearch) {
			... on Estate {
				id
				name
			}
			... on Neighborhood {
				id
				name
				estate {
					id
					name
				}
			}
			__typename
		}
	}
`;

const PROJECTS_COUNT = 3;
const PROJECTS_QUERY = gql`
	query SearchProject($title: String) {
		searchListing(params: { operation_type_id: 1, projects:true, projectTitle: $title }, first: ${PROJECTS_COUNT}, page: 1) {
			data {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

const REF_QUERY = gql`
	query searchByRef($code: String!) {
		searchByRef(refCode: $code) {
			... on Project {
				id
				title
				link
				isEspecial
			}
			... on Property {
				${FRAGMENT_PROPERTY_CARD.query()}
			}
		}
	}
`;

export interface KeywordLocationProps {
	className?: string;
	searchByProject?: boolean;
	searchByReference?: boolean;
	isSearchForHome?: boolean;
	filterChanged: ({}: any) => void;
	handleLocations?: ([]) => void;
	locations?: any[];
	changeKeyword?: any;
}

export const useKeywordLocation = ({
	filterChanged,
	searchByReference,
	searchByProject,
	locations = [],
}: KeywordLocationProps) => {
	// hook setup
	const [keyword, setKeyWord] = useState<string>("");
	const [location, setLocation] = useState(null);

	useEffect(() => updateFilters(), [location]);

	// hook functions
	const onSelect = (val, opt) => {
		setLocation(opt);
		setKeyWord(val);
	};

	const onSearch = (val: string) => {
		if (isValidCharacters(val) || val == "") {
			setKeyWord(val);
			search(val);
		}
	};

	const [search] = useDebouncedCallback((val: string) => {
		if (val.length > 0) {
			searchQuery({ variables: { strSearch: val } });
		}
		if (searchByProject && val.length > 0) projectQuery({ variables: { title: val } });
		if (searchByReference && val.length >= 5) refQuery({ variables: { code: val } });
	}, 500);

	const [searchQuery, { data, loading }] = useLazyQuery(KEYWORD_LOCATION_QUERY);
	const [projectQuery, { data: dataProjects, loading: loadingProjects }] = useLazyQuery(
		PROJECTS_QUERY
	);

	const [refQuery, { data: dataRef, loading: loadingRef }] = useLazyQuery(REF_QUERY);

	const updateFilters = () => {
		if (location) {
			if (location.__typename == "keyword") {
				if (locations.length > 0) {
					const firstLocation = locations[0];
					if (firstLocation.estate) {
						filterChanged({
							neighborhood_id: [
								{ text: firstLocation.name, value: firstLocation.id },
							],
							estate_id: {
								text: firstLocation.estate.name,
								value: firstLocation.estate.id,
							},
							searchstring: null,
						});
					} else {
						filterChanged({
							neighborhood_id: null,
							estate_id: { text: firstLocation.name, value: firstLocation.id },
							searchstring: null,
						});
					}
				} else {
					filterChanged({
						neighborhood_id: null,
						estate_id: null,
						searchstring: { text: "Palabra Clave: " + keyword, value: keyword },
					});
				}
			} else if (location.estate)
				filterChanged({
					neighborhood_id: [{ text: location.name, value: location.id }],
					estate_id: { text: location.estate.name, value: location.estate.id },
					searchstring: null,
				});
			else {
				filterChanged({
					neighborhood_id: null,
					estate_id: { text: location.name, value: location.id },
					searchstring: null,
				});
			}
		}
	};

	// hook output API
	return {
		keyword,
		setKeyWord,
		location,
		show: true,
		onSearch,
		onSelect,
		searchResults: data,
		refResults: dataRef,
		projectsResults: dataProjects,
		searchLoading: loading || loadingRef || loadingProjects,
	};
};

