import "./styles.less";

import { useContext } from "react";

import { Button, Typography } from "antd";

import { ImagenOptimizada as Img } from "../../../Components/Image/_new_web";
//import { ImagenOptimizada as Img } from "../../../Components/Image/web";
import { PlusOutlined } from "@ant-design/icons";
import { useBannerHome } from "./BannerHome.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { ConfigStateContext } from "../../../Contexts/Configurations/context";

const { Paragraph } = Typography;

export function BannerHome() {
	const { loading, data, error } = useBannerHome({});
	const { theme } = useTheme();
	const { country_code } = useContext(ConfigStateContext);

	if (loading) return null;

	const d =
		error || !data
			? {
					id: "00",
					name: "default",
					image:
						country_code === "ce3"
						? "https://cdn2.casaseneleste.com/web/5bb64f6944a7e_infocdn__portada-ceee2018-min.jpg"
						:"https://cdn2.infocasas.com.uy/web/5786707324f50_infocdn__home30-opti.jpg",
			  }
			: data;

	return (
		<div className={"destacadoHome" + ` ${d.name}`} key={"destacado_home_" + d.id}>
			{d.image != "" && <Img src={d.image} className="background" alt={d.name} width={1200} height={480} />}
			{typeof d.url != "undefined" && (
				<>
					<div
						className={"background-container " + (d.html_logo == "" ? "big" : "small")}>
							<Img src={d.logo} alt={d.name} className="logo" />
							<Paragraph className="text" ellipsis={{ rows: 3 }}>
								<span dangerouslySetInnerHTML={{ __html: d.html_logo }} />
							</Paragraph>
						<Button
							className={"more"}
							href={d.url}
							target="_blank"
							icon={<PlusOutlined />}
							children={"Ver Más"}
						/>
					</div>
				</>
			)}
			<style jsx>{`
				.destacadoHome :global(.background-container) {
					background-color: ${d.logo_bg_color};
				}
				.destacadoHome :global(picture),
				.destacadoHome :global(.logo) {
					width: ${typeof data?.logo_width != "undefined"
						? data?.logo_width
						: "185"}px !important;
				}
				.destacadoHome :global(.text) {
					color: ${d.logo_text_color};
				}
				.destacadoHome :global(.ant-btn) {
					color: ${d.logo_text_color};
					border: ${"solid 1px " + d.logo_text_color};
				}
				.destacadoHome :global(.ant-btn:hover) {
					color: ${d.logo_bg_color};
					background: ${d.logo_text_color};
				}

				@media screen and (max-width: ${theme.breakPoints.lg}) {
					.destacadoHome :global(.background-container) {
						background-color: ${d.logo_bg_color};
					}
					.destacadoHome :global(picture),
					.destacadoHome :global(.logo) {
						min-width: ${d.logo_mobile_width
							? d.logo_mobile_width
							: "120"}px !important;
					}
				}
			`}</style>
		</div>
	);
}
