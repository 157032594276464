import "./styles.less";

import { Button, Col, Form, Row } from "antd";
import {
	KeywordLocation,
	setRecientLocations,
} from "../../../Components/Filters/KeywordLocation/web";
import React, { useEffect, useState } from "react";
import { isTemporal, showPropertyType } from "../../../Utils/Functions";

import { OperationType } from "../../../Components/Filters/OperationType/web";
import { PropertyType } from "../../../Components/Filters/PropertyType/web";
import { SearchOutlined } from "@ant-design/icons";
import { TemporalFilter } from "../../../Components/Filters/TemporalFilter/web";
import { encodeHashUrl } from "../../../Utils/Functions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHomeFilters } from "./HomeFilters.hook";
import { useRouter } from "next/router";
import { useTheme } from "../../../Styles/ThemeHook";
// import { useGoogleAnalytics } from "shared-components/GlobalHooks/web/GoogleAnalytics.hook";
import { useTemporalFilter } from "shared-components/Components/Filters/TemporalFilter/TemporalFilter.hook";

export const HomeFilters = () => {
	const { filters, changeFilters, filtersTags, search } = useHomeFilters();

	const doSearch = () => search.send({ variables: { params: filters } });

	const [location, setLocation] = useState([]);
	const [keyword, setKeyword] = useState("");

	const screens = useBreakpoint();
	const { theme } = useTheme();
	const router = useRouter();
	// const GA = useGoogleAnalytics();

	const showOperationFilter = true;
	const showTemporalFilter = isTemporal(filters.operation_type_id);
	const showPropertyFilter = showPropertyType(filters.operation_type_id);

	const {
		setShowSeasonInput,
		showSeasonInput,
	} = useTemporalFilter();

	const handleChangeSeason = (show) => {
		setShowSeasonInput(show)
	}

	const handleLocations = data => {
		setLocation(data);
	};

	const handleSearch = () => {
		// GA.Event({
		// 	category: 'user_event',
		// 	action: 'home_search',
		// 	label: 'search_by_location'
		// });
		if (location.length > 0) {
			const firstLocation = location[0];
			if (firstLocation.estate) {
				changeFilters({
					neighborhood_id: [{ text: firstLocation.name, value: firstLocation.id }],
					estate_id: { text: firstLocation.estate.name, value: firstLocation.estate.id },
					searchstring: null,
				});
			} else {
				changeFilters({
					neighborhood_id: null,
					estate_id: { text: firstLocation.name, value: firstLocation.id },
					searchstring: null,
				});
			}
		} else {
			if (keyword) {
				changeFilters({
					neighborhood_id: null,
					estate_id: null,
					searchstring: { text: keyword, value: keyword }
				});
			} else {
				search.send({ variables: { params: filters } });
			}
		}
	};

	useEffect(() => {
		if (filters.estate_id != null || filters.searchstring != null || filters.maxPrice != null) {
			search.send({ variables: { params: filters } });
		}
	}, [filters.estate_id, filters.searchstring, filters.maxPrice]);

	useEffect(() => {
		if (search.response.data && !search.response.loading) {
			setRecientLocations(filtersTags.neighborhood_id, filtersTags.estate_id);
			router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				search.response.data.searchUrl.url
			);
		}
	}, [search.response]);

	return (
		<Form onFinish={doSearch} className="home-filters home-filters-IC">
			<Row
				justify={"center"}
				gutter={[
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: 0,
					},
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: theme.spacing.smSpacing,
					},
				]}>

				{/* OPERTATION TYPE FILTER */}
				{(showOperationFilter) && (
					<Col
						xs={showTemporalFilter ? 24 : 12}
						sm={showTemporalFilter ? 24 : 12}
						lg={22}>
						<OperationType
							filterChanged={changeFilters}
							home
							inputType={screens.lg ? "butons" : "select"}
							handleChangeSeason={handleChangeSeason}
							showSeasonInput={showSeasonInput}
						/>
					</Col>
				)}

				{/* PROPERTY TYPE FILTER */}
				{showPropertyFilter && (
					<Col xs={12} sm={12} lg={8} className="big-input">
						<PropertyType filterChanged={changeFilters} />
					</Col>
				)}

				{/* TEMPORAL FILTER */}
				{showTemporalFilter && (
					<Col xs={24} sm={24} lg={10} className="big-input temporal-filter-container">
						<TemporalFilter filterChanged={changeFilters} showSeasonInput={showSeasonInput} handleChangeSeason={handleChangeSeason} />
					</Col>
				)}

				<Col flex={"1"} className="big-input">
					<Row gutter={0}>
						<Col flex={"1"}>
							{/* KEYWORD - LOCATION FILTER */}
							<KeywordLocation
								filterChanged={changeFilters}
								searchByReference={true}
								searchByProject={false}
								handleLocations={handleLocations}
								locations={location}
								changeKeyword={setKeyword}
								isSearchForHome={true}
							/>
						</Col>
						<Col className={"search-button-container"}>
							<Button
								className="search-button superPrimary"
								type="primary"
								loading={search.response.loading}
								onClick={handleSearch}
								icon={<SearchOutlined />}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
