import { gql, useLazyQuery } from "@apollo/client";
import { useFilters } from "../../../Components/Filters/Filters.hook";
import { useQuery } from "@apollo/client";
import { QUERY_AVAILABLE_FILTERS } from "shared-components/GQL";
/*
import { FRAGMENT_DATERANGE } from "../../../Components/Filters/TemporalFilter/DateRange/DateRange.hook";
import { FRAGMENT_OPERATIONTYPES } from "../../../Components/Filters/OperationType/OperationType.hook";
import { FRAGMENT_PROPERTYTYPES } from "../../../Components/Filters/PropertyType/PropertyType.hook";
import { FRAGMENT_SEASONS } from "../../../Components/Filters/TemporalFilter/Seasons/Seasons.hook";
*/

/*
const QUERY_AVAILABLE_FILTERS = gql`
  query availableFilters{
    availableFilters(show_in_home:true){
		  ${FRAGMENT_OPERATIONTYPES.query()}
		  ${FRAGMENT_PROPERTYTYPES.query()}
      ${FRAGMENT_SEASONS.query()}
      ${FRAGMENT_DATERANGE.query()}
    }
  }
`;
*/

const QUERY_SEARCH_URL = gql`
	query searchUrl($params: SearchParamsInput!) {
		searchUrl(params: $params) {
			url
		}
		error
	}
`;

export const useHomeFilters = () => {
	const { filters, changeFilters, filtersTags } = useFilters();
	const doQuery = useQuery(QUERY_AVAILABLE_FILTERS);

	const [getSearchURL, response] = useLazyQuery(QUERY_SEARCH_URL, {
		onError: errURL => console.error("ERROR", errURL),
	});

	return {
		filters,
		changeFilters,
		filtersTags,
		search: {
			send: getSearchURL,
			response: response,
		},
	};
};

