import { Button, Skeleton, Typography } from "antd";

import { ImagenOptimizada as Img } from "../../../../Components/Image/web";
import React from "react";

const { Link } = Typography;
let relValue =''
export const BannerSubHomeItem = ({ image, link, position, key, loading }) => {
	if(link){
		if(link[0] != '/' && !link.includes('infocasas.com.')) relValue='nofollow'
		else relValue=''
	}
	return (
		<div
			className="destacado"
			key={key ?? ""}
			style={{ animationDelay: 400 * position + "ms" }}>
			{loading ? (
				<Skeleton.Image key={"banner-sub-home" + key} className="image-container" />
			) : (
				<Link className="image-container" href={link} target="_blank" rel={relValue}>
					<Img src={image} alt={image} />
					<Button className="btn-ver">{"Conocé más"}</Button>
				</Link>
			)}
		</div>
	);
};
